import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "reactstrap"
import Lottie from "react-lottie"
import { IoIosPlayCircle } from "react-icons/io"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

import carebitGridAnimationData from "src/assets/animation/carebit-grid.json"

import GenericAutomationChartContainer from "src/containers/charts/GenericAutomationChartContainer"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import HomepageVideoModal from "src/components/index/HomepageVideoModal"

import FeatureBadge from "src/components/features/FeatureBadge"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"
import TestimonialCard from "src/components/TestimonialCard"

import workflowIclLetterFlypast from "src/assets/img/screenshots/automation-workflow.png"
import composeTemplate from "src/assets/img/screenshots/compose-template.png"
import patientPortalNewLetterFlow from "src/assets/video/patient-portal-new-letter.gif"
import reminderEmail from "src/assets/img/screenshots/revenue-enhancement-reminder-email.png"
import automationPayInvoiceOnline from "src/assets/video/automation-pay-invoice-online.gif"
import videoCallInvitationEmail from "src/assets/img/screenshots/video-call-email-invitation.png"

import dominicAvatar from "src/assets/img/dominic-medium600.jpg"
import chrisAvatar from "src/assets/img/testimonials/chris-medium600.jpg"
import robertLindaKempAvatar from "src/assets/img/testimonials/robert-linda-kemp.jpg"
import angieAvatar from "src/assets/img/testimonials/angie.jpg"

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Layout>
      <Seo
        title="Home"
        pathname=""
        description="Carebit is the best way to run your private practice. Automate time-consuming tasks, take online bookings and much more."
      />

      <HomepageVideoModal showModal={showModal} setShowModal={setShowModal} />

      <div className="Index-gradient">
        <div
          className="ColouredDivider Hero"
          style={{ padding: "100px 0px 300px" }}
        >
          <Container>
            <Row className="u-centerFlex">
              <Col
                lg={{ size: 5, offset: 0 }}
                md={{ size: 10, offset: 1 }}
                className="Grid-left u-marginBottom60"
              >
                <h1 style={{ lineHeight: "1.3" }}>
                  Everything you need to manage, automate & grow your private
                  practice.
                </h1>

                <p className="u-marginTop20 u-marginBottom40">
                  Use Carebit to automate the creation of letters, emails and
                  invoices, take online bookings and manage your practice on one
                  unified platform.
                </p>

                <Button
                  color="secondary"
                  size="lg"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      top: "-3px",
                      marginRight: "10px",
                    }}
                  >
                    <IoIosPlayCircle size="24px" />
                  </div>
                  Watch the video
                </Button>
              </Col>

              <Col
                lg={{ size: 6 }}
                md={{ size: 10, offset: 1 }}
                className="Grid-right"
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: carebitGridAnimationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div>
        <Container>
          <section>
            <Row>
              <Heading title="Features">
                Learn more about what you can do with Carebit.
              </Heading>

              <Col md={12}>
                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 5 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <img
                        alt="Carebit Workflow - Initial Consultation Letter"
                        src={workflowIclLetterFlypast}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>

                    <Col
                      lg={{ size: 6, offset: 1 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <Link to="/features/automation">
                        <div className="u-marginBottom20">
                          <FeatureBadge slug="automation" />
                          <h5>Automation: save time and money</h5>
                        </div>
                      </Link>
                      <p className="u-midGrey">
                        Carebit’s powerful Workflow feature allows you to
                        automate common tasks in your clinic, such as generating
                        letters and emails to patients in response to events
                        like invoices and bookings being created. You can even
                        add conditions too, such as which doctor the booking is
                        for, which service the booking is for and whether the
                        patient is self-pay or insured. Automation is reliable,
                        runs 24 hours a day and is included for free in every
                        Carebit subscription.
                      </p>

                      <div style={{ marginTop: "40px" }}>
                        <Link to="/features/automation">
                          <Button color="secondary" size="md">
                            Learn more about automation &rarr;
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </section>

                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 6 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <Link to="/features/compose-letters">
                        <div className="u-marginBottom20">
                          <FeatureBadge slug="compose-letters" />
                          <h5>
                            Write and send patient letters to GPs, colleagues
                            and patients
                          </h5>
                        </div>
                      </Link>
                      <p className="u-midGrey">
                        Time-consuming drafting and editing is over. Carebit’s
                        Compose feature with smart templates automatically
                        generate the header, footer, addressee’s name and
                        address, the date of the letter, the patient’s details
                        and even your signature and title - saving you hours of
                        effort.
                      </p>

                      <p className="u-midGrey">
                        One-click booking of followups, voice-to-text
                        transcription as well as a simple way to email the
                        letter encrypted to multiple recipients mean faster,
                        cheaper and much more efficient letter writing.
                      </p>

                      <div style={{ marginTop: "40px" }}>
                        <Link to="/features/compose-letters">
                          <Button color="secondary" size="md">
                            Read about Carebit Compose &rarr;
                          </Button>
                        </Link>
                      </div>
                    </Col>

                    <Col
                      lg={{ size: 5, offset: 1 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <img
                        alt="Carebit Compose - letter template"
                        src={composeTemplate}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </section>

                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 5 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <img
                        alt="Carebit - Patient Portal"
                        src={patientPortalNewLetterFlow}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>

                    <Col
                      lg={{ size: 6, offset: 1 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <Link to="/features/patient-portal">
                        <div className="u-marginBottom20">
                          <FeatureBadge slug="patient-portal" />
                          <h5>
                            Deliver a memorable patient experience with the
                            patient portal
                          </h5>
                        </div>
                      </Link>
                      <p className="u-midGrey">
                        Gone are the days of patients having to email or ring
                        your clinic to obtain copies of letters or test results.
                        As soon as they’re uploaded, the patient can access them
                        by logging into the secure, encrypted online portal. You
                        can always control what is shared with the patient.
                      </p>

                      <div style={{ marginTop: "40px" }}>
                        <Link to="/features/patient-portal">
                          <Button color="secondary" size="md">
                            Learn more about the patient portal &rarr;
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </section>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={10000}
                showStatus={false}
              >
                <div>
                  <TestimonialCard
                    introText="Carebit for secretaries and practice managers"
                    author="Mrs Angie Newman"
                    authorSubtext="Practice manager for a leading London paediatrics practice"
                    avatarSrc={angieAvatar}
                  >
                    <p>
                      “We are an extremely busy practice, having over 4,000
                      patients and booking in excess of 100 appointments per
                      week, so we needed a system that was going to be robust
                      enough to keep up, but the most important aspect was the
                      online booking system and the facility for patients to
                      book their own appointments at times that are convenient
                      for them. We also needed a company to work with that had a
                      good after sales support system.
                    </p>
                    <p>
                      We found that other companies offering online booking
                      systems offered some great functions, but once you were
                      sold the product you were left to your own devices to make
                      it work for you.
                    </p>
                    <p>
                      This was what made our decision to go to Carebit over
                      similar companies in the same market so much easier.
                      Carebit was offering a great product, but also support and
                      guidance after the initial sales process was finished, and
                      this is what we needed!”
                    </p>
                  </TestimonialCard>
                </div>

                <div>
                  <TestimonialCard
                    introText="Carebit for secretaries and practice managers"
                    author="Mrs Angie Newman"
                    authorSubtext="Practice manager for a leading London paediatrics practice"
                    avatarSrc={angieAvatar}
                  >
                    <p>
                      “We wanted to move to a system that allowed patients easy
                      access to their results, letters, appointment details etc
                      in a much more fluid manner than the way we were currently
                      running. Nothing was automated and every task had a high
                      manual demand on the administrative staff within the
                      practice.
                    </p>
                    <p>
                      Carebit offered not only a solution to allowing patients
                      access to the information they required via a portal, but
                      also that a huge amount of the administrative and
                      repetitive tasks became automated and were generated by
                      the system without human intervention being required.
                    </p>
                    <p>
                      No more sending confirmations for appointments, reminders
                      for appointments, invoices etc. The system did this all
                      for us automatically.”
                    </p>
                  </TestimonialCard>
                </div>

                <div>
                  <TestimonialCard
                    introText="Carebit for secretaries and practice managers"
                    author="Mrs Angie Newman"
                    authorSubtext="Practice manager for a leading London paediatrics practice"
                    avatarSrc={angieAvatar}
                  >
                    <p>
                      “Moving to Carebit has improved the day to day running of
                      our practice, without a doubt, and has made the whole
                      booking experience for our patients so much easier. The
                      administrative team have more time to concentrate on the
                      more important tasks of running the practice and our
                      doctors have a great system to use in clinic where
                      everything they need is at the touch of a button. And{" "}
                      <strong>
                        the system gives you the ability to communicate with
                        each other in a much more organised and structured
                        manner.
                      </strong>
                    </p>
                    <p>
                      We as a practice could not recommend Carebit enough, it
                      has improved the running of our practice in so many more
                      ways than we could have envisaged.”
                    </p>
                  </TestimonialCard>
                </div>

                <div>
                  <TestimonialCard
                    introText="Carebit for doctors"
                    author="Professor Christopher Eden"
                    authorSubtext="Leading robotic surgeon, Santis"
                    avatarSrc={chrisAvatar}
                  >
                    <p>
                      “As a successful and busy surgeon, automating as much of
                      the administrative side of my practice as possible has
                      long been a goal but was not achievable until Carebit.
                    </p>
                    <p>
                      Working with Carebit to produce an infinitely-customisable
                      and adaptable software solution has{" "}
                      <span className="u-fontBold">
                        transformed my confidence in how my practice is run and
                        has freed up a great deal of my secretary’s time to
                        attend to other tasks.
                      </span>
                      ”
                    </p>
                  </TestimonialCard>
                </div>

                <div>
                  <TestimonialCard
                    introText="Carebit for patients"
                    author="Linda &amp; Robert Kemp"
                    authorSubtext="Recent patients of a Carebit-powered private practice"
                    avatarSrc={robertLindaKempAvatar}
                  >
                    <p>
                      “I think our main impressions of Carebit are the speed and
                      efficiency of being kept up to date with every step of the
                      process from consultations through to test results. In
                      times of stress there is nothing worse than waiting for
                      letters, phone calls or appointments to keep you informed,
                      but we always knew we could refer to Carebit for our
                      information and especially to review what had been said
                      after a consultation. To be able to study test results in
                      your own environment leads to much better understanding
                      than having to take everything in during a consultation.
                    </p>
                    <p>
                      The speed of our consultant in being able to generate
                      letters to our GP which we could read almost instantly was
                      particularly impressive. We used Carebit on a PC, tablet
                      and smartphone so information was always with us. My file
                      of paperwork was practically redundant. Even now we are
                      still using Carebit to see PSA test results carried out by
                      our GP. Although our surgery has an online operation, most
                      of the sections such as medical records and test results
                      are not working, but as they have been forwarded to our
                      consultant and added to the system we can see them via
                      Carebit.
                    </p>
                    <p>
                      Overall as a patient experience we are extremely impressed
                      with the Carebit system. It made life so much easier at a
                      difficult time and for that we are very grateful.”
                    </p>
                  </TestimonialCard>
                </div>
              </Carousel>
            </Container>
          </div>
        </div>

        <Container>
          <section>
            <Row>
              <Col md={12}>
                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 6 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <Link to="/features/revenue-enhancement">
                        <div className="u-marginBottom20">
                          <FeatureBadge slug="revenue-enhancement" />
                          <h5>Revenue enhancement: eliminate bad debt</h5>
                        </div>
                      </Link>
                      <p className="u-midGrey">
                        Carebit’s sophisticated tools - designed in partnership
                        with our customers billing millions of pounds a year -
                        can eliminate bad debts. Invoice payment reminder emails
                        can be configured to be sent automatically, and
                        appointments even cancelled on the day if payment is not
                        received. You don’t have to lift a finger.
                      </p>

                      <div style={{ marginTop: "40px" }}>
                        <Link to="/features/revenue-enhancement">
                          <Button color="secondary" size="md">
                            Read about revenue enhancement &rarr;
                          </Button>
                        </Link>
                      </div>
                    </Col>

                    <Col
                      lg={{ size: 5, offset: 1 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <img
                        alt="Carebit - payment reminder email"
                        src={reminderEmail}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </section>

                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 5 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <img
                        alt="Carebit - video call invitation email"
                        src={videoCallInvitationEmail}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>

                    <Col
                      lg={{ size: 6, offset: 1 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <div className="u-marginBottom20">
                        <FeatureBadge slug="video-calls" />
                        <h5>Easily arrange video calls with patients</h5>
                      </div>
                      <p className="u-midGrey">
                        With Carebit Video, video calls are built right into the
                        platform. Patients receive an invitation on the day of
                        the appointment and can easily join from any device,
                        while the clinician can join the call straight from
                        their Calendar.
                      </p>
                    </Col>
                  </Row>
                </section>

                <section>
                  <Row className="u-centerFlex">
                    <Col
                      lg={{ size: 5 }}
                      md={{ size: 12 }}
                      className="SplitContentRow-graphic"
                    >
                      <Link to="/features/online-payment">
                        <div className="u-marginBottom20">
                          <FeatureBadge slug="online-payment" />
                          <h5>Online payment: patients can pay 24/7</h5>
                        </div>
                      </Link>
                      <p className="u-midGrey">
                        After receiving an invoice by email, patients can pay
                        instantly online using their credit or debit card. It’s
                        secure and convenient for them and improves your cash
                        flow, as well as saving time on handling bounced cheques
                        and trips to the bank with cash. Automatic payouts are
                        made weekly into the doctor’s bank account.
                      </p>

                      <div style={{ marginTop: "40px" }}>
                        <Link to="/features/online-payment">
                          <Button color="secondary" size="md">
                            Learn more about paying online &rarr;
                          </Button>
                        </Link>
                      </div>
                    </Col>

                    <Col
                      lg={{ size: 6, offset: 1 }}
                      md={12}
                      className="SplitContentRow-text"
                    >
                      <img
                        alt="Carebit - pay invoice online"
                        src={automationPayInvoiceOnline}
                        className="u-outline"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </section>

                <ViewMoreFeatures />
              </Col>
            </Row>
          </section>
        </Container>
      </div>

      <div>
        <Container>
          <section>
            <Row className="u-centerFlex">
              <Col lg={6} md={12} className="SplitContentRow-text">
                <Row>
                  <Heading title="Case study">
                    Santis: automating secretarial tasks
                  </Heading>
                </Row>
                <p>
                  <a href="https://www.santishealth.org">Santis</a> is a leading
                  London clinic specialising in advanced robotic surgery for
                  prostate cancer. They treat hundreds of men every year, and
                  introduced Carebit to automate many administrative tasks that
                  are critical to running their practice.
                </p>

                <p>
                  The initial results were astonishing. In the first full month
                  of service,{" "}
                  <span className="u-fontBold">
                    Carebit performed 38 hours worth of human work in just 17
                    minutes, saving them over £700 in secretarial costs
                  </span>
                  . Not only was there an enormous time saving, but the work
                  performed by Carebit was free of human error.
                </p>

                <Link to="/case-studies/santis-automating-secretarial-tasks">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ marginTop: "20px" }}
                  >
                    Read full case study &rarr;
                  </Button>
                </Link>
              </Col>
              <Col lg={6} md={12} className="SplitContentRow-graphic">
                <GenericAutomationChartContainer
                  chartData={[
                    { x: "Secretary", y: 1 },
                    { x: "Carebit", y: 136 },
                  ]}
                  countUpTextBefore="A "
                  countUpTextAfter="x productivity improvement"
                />
              </Col>
            </Row>
          </section>
        </Container>
      </div>

      <Container>
        <section>
          <Row>
            <Heading title="Our story">Discover how Carebit came to be</Heading>
            <Col
              lg={{ size: 6, offset: 0 }}
              md={{ size: 10, offset: 1 }}
              className="u-centerFlex Grid-left"
              style={{ flexDirection: "column" }}
            >
              <img
                src={dominicAvatar}
                style={{
                  width: "200px",
                  margin: "0 auto 40px",
                  display: "block",
                }}
                className="u-circle"
                alt="Dominic, Carebit creator"
              />
              <h5>Dominic</h5>
              <h5 className="subtext">Principal, Carebit</h5>
            </Col>
            <Col lg={{ size: 6, offset: 0 }} md={{ size: 10, offset: 1 }}>
              <p>
                When I was helping to establish a private medical practice in
                London, we struggled to find suitable software to assist us. We
                wanted something that would help with taking bookings, handling
                patient data and ultimately being flexible enough to cope with a
                growing business that wanted to innovate. There was no single
                integrated system that would do it all, and much of the software
                available was outdated, inflexible and inconvenient to use.
              </p>
              <p>
                We all agreed there should be a better way: creating a modern,
                flexible platform to simultaneously empower clinicians to do
                their best work and to deliver an exceptional digital experience
                for patients. It has been designed by experienced technologists
                working closely with doctors and administrative staff, and
                tested thoroughly with patients.
              </p>
              <p>
                This is Carebit: our vision of how a modern private practice
                could and should work with the help, not hindrance, of
                technology. We hope it will come to be as critical to your
                success as it has become to ours.
              </p>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default IndexPage
