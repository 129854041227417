import React from "react"
import ModalVideo from "react-modal-video"

const HomepageVideoModal = ({ showModal, setShowModal }) => {
  return (
    <div>
      <ModalVideo
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        channel="vimeo"
        videoId="398753670"
      />
    </div>
  )
}

export default HomepageVideoModal
